
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {ILicenceItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";

interface IState {
  list: ILicenceItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: ILicenceItem
  reason: string
  show: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 80

  },
  {
    title: '行驶证',
    key: 'driving_licence',
  },
  {
    title: '道路运输证',
    key: 'licence',
  },
  {
    title: '车辆信息',
    key: 'carInfo',
  },
  {
    title: '提交时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '查看图片',
    key: 'image',
  },
  {
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '审核信息',
    dataIndex: 'audit',
    key: 'audit',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'LicenceList',
  components: {EditOutlined, CheckOutlined, CloseOutlined},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      item: undefined,
      reason: '',
      visible: false,
      show: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
        type: '', // 类型 1为用户，2为商家，不传全部
        shop_admin_id: null,
        name: ''
      },
    })

    const statusText = [{name: '审核中', color: 'orange'}, {name: '审核通过', color: 'green'}, {name: '审核未通过', color: 'red'}]


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (!params.type) {
        delete params.type
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogisticsAudit.getLicenceList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 设置modal显示隐藏
    const setVisible = (item: ILicenceItem | undefined, flg: boolean) => {
      state.visible = flg
      state.reason = ''
      if (item) {
        state.item = item
      } else {
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 审核
    const onAudit = async (item: ILicenceItem, flg: boolean) => {
      if (!flg && !state.reason) {
        message.destroy()
        message.warning('请输入不通过的原因')
        return
      }
      state.loading = true
      const res = await ApiLogisticsAudit.auditLicence({
        "courier_application_id": item.id,
        "reason": flg ? "审核通过" : state.reason,
        "is_passed": flg
      })
      state.loading = false
      setVisible(item, false)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    // 上传车辆信息
    const updateCar = async (item: ILicenceItem) => {
      state.loading = true
      const res = await ApiLogisticsAudit.updateCar(item.id)
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        if(res?.msg && typeof res?.msg === 'string'){
          const obj = JSON.parse(res.msg);
          message.error(obj?.msg || '上报失败');
          return
        }
        message.error('上报失败');
      }
    }

    const setShow = (item: ILicenceItem, flg: boolean) => {
      state.show = flg
      if (item) {
        state.item = item
      } else {
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 详情
    const goDetail = (item: ILicenceItem) => {
      router.push({path: '/audit/licence_detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any) => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    // 时间格式化
    const formatTime = (time: any, showTime = true) => {
      if (time === '长期') {
        return time
      }
      return dateFormat(time * 1000, showTime)
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      statusText,
      goDetail,
      handleTableChange,
      onSearch,
      resetSearch,
      formatTime,
      onAudit,
      setVisible,
      setShow,
      updateCar
    }
  }
})
